<template>
  <div class="my-4">
    <div class="bg-custom-gray p-6 flex justify-start flex-col text-left">
      <div class="text-lg font-bold">ค้นหาคำร้องและคำวินิจฉัยจาก</div>
      <form @keyup.enter="searchKeyword">
        <div class="text-left my-2">
          <div>ค้นหาจากข้อมูล</div>
          <select
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            name="doctype"
            v-model="doc_type"
          >
            <option value>(ทั้งหมด)</option>
            <option value="คำพิพากษาศาลฎีกา">คำพิพากษาศาลฎีกา</option>
            <option value="คำสั่งคำร้อง">คำสั่งคำร้อง</option>
            <option value="คำวินิจฉัย(คำสั่ง)">คำวินิจฉัย(คำสั่ง)</option>
            <option value="คำสั่งศาลฎีกา">คำสั่งศาลฎีกา</option>
          </select>
        </div>
        <div class="text-left my-2">
          <div>ทุกเงื่อนไขทั้งหมดนี้</div>
          <div class="color-custom-gray-100 text-sm">e.g. ไก่ ไข่ (ค้นหาเป็น ไก่ และ ไข่)</div>
          <input
            v-model="keyword"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>บางเงื่อนไขเหล่านี้</div>
          <div class="color-custom-gray-100 text-sm">e.g. ไก่ ไข่ (ค้นหาเป็น ไก่ หรือ ไข่)</div>
          <input
            v-model="or_keyword"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>ยกเว้นเงื่อนไขเหล่านี้</div>
          <div class="color-custom-gray-100 text-sm">
            e.g. ไก่ ไข่ (ค้นหาเป็น ยกเว้น ไก่ และ ไข่)
          </div>
          <input
            v-model="not_keyword"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>หมายเลขคำพิพากษา (หมายเลขแดง)</div>
          <input
            v-model="doc_no"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>ผู้พิพากษา</div>
          <input
            v-model="judge"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>ลูกความ</div>
          <input
            v-model="litigant"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          />
        </div>
        <div class="text-left my-2">
          <div>ค้นหาช่วงปี</div>
          <div class="flex flex-row">
            พ.ศ.
            <input
              v-model="start_year"
              class="
                text-sm
                border-1 border-color-gray
                rounded-none
                w-[100px]
                xl:w-40
                outline-none
                px-2
                py-1
              "
              type="int"
            />
            <div class="px-2">ถึง</div>
            พ.ศ.
            <input
              v-model="end_year"
              class="
                text-sm
                border-1 border-color-gray
                rounded-none
                w-[100px]
                xl:w-40
                outline-none
                px-2
                py-1
              "
              type="int"
            />
          </div>
        </div>
        <div class="text-left my-2">
          <div>มาตรา</div>
          <div class="color-custom-gray-100 text-sm">ตย. "ม. 250"</div>
          <!-- <input
            v-model="temp_law"
            class="
              text-sm
              border-1 border-color-gray
              rounded-none
              w-full
              xl:w-96
              outline-none
              px-2
              py-1
            "
            type="input"
          /> -->
          <v-select
            class="w-full xl:w-96 bg-white"
            v-model="law_filter"
            :options="optionList"
            multiple
            taggable
            :filterable="false"
            @search="onSearch"
          >
            <template slot="no-options"> กรุณาเลือกมาตรา </template>
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
          </v-select>
        </div>
        <div class="mt-4">
          <!-- <div class="text-red">Hello</div> -->
          <button
            class="bg-custom-purple text-white w-24 py-1 mx-3"
            type="button"
            @click="searchKeyword"
          >
            ค้นหา
          </button>
          <button class="bg-custom-red text-white w-24 py-1 mx-3" type="button">ล้างข้อมูล</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FuzzySearch from 'fuzzy-search';

export default {
  data() {
    return {
      doc_type: '',
      keyword: '',
      or_keyword: '',
      not_keyword: '',
      doc_no: '',
      temp_law: '',
      start_year: '',
      end_year: '',
      state: {
        error: '',
      },
      judge: '',
      litigant: '',
      law_filter: '',
      search: '',
      optionList: [],
    };
  },
  mounted() {
    this.$store.dispatch('fetchResult', this.$route.query);
  },
  computed: {
    // aggregations() {
    //   const aggregations = this.$store.state.aggregations;
    //   if (this.search != '') {
    //     console.log('SEARCH');
    //     let optionList = [];
    //     aggregations.forEach(agg => {
    //       if (agg.key.toUpperCase().includes(this.search)) {
    //         optionList.push(agg.key);
    //       }
    //     });
    //     return optionList;
    //   }
    //   return [];
    // },
  },
  methods: {
    validateData() {
      const valid = this.keyword || this.or_keyword;
      if (valid) this.state.error = 'กรุณาใส่เงื่อนไขอย่างน้อย 1 เงื่อนไข';
      return valid;
    },
    searchKeyword() {
      let queryParam = '';
      const temp_law = this.law_filter
        ? this.law_filter.reduce(
            (q, law, index) => (this.law_filter.length - 1 == index ? q + law : q + law + ', '),
            ''
          )
        : '';
      queryParam = this.doc_type ? `${queryParam}&doc_type=${this.doc_type}` : queryParam;
      queryParam = this.keyword ? `${queryParam}&keyword=${this.keyword}` : queryParam;
      queryParam = this.or_keyword ? `${queryParam}&or_keyword=${this.or_keyword}` : queryParam;
      queryParam = this.not_keyword ? `${queryParam}&not_keyword=${this.not_keyword}` : queryParam;
      queryParam = this.doc_no ? `${queryParam}&doc_no=${this.doc_no}` : queryParam;
      queryParam = temp_law ? `${queryParam}&temp_law=${temp_law}` : queryParam;
      queryParam = this.start_year ? `${queryParam}&start_year=${this.start_year}` : queryParam;
      queryParam = this.end_year ? `${queryParam}&end_year=${this.end_year}` : queryParam;
      queryParam = this.judge ? `${queryParam}&judge=${this.judge}` : queryParam;
      queryParam = this.litigant ? `${queryParam}&litigant=${this.litigant}` : queryParam;

      if (queryParam.length) {
        // window.open(`/result?${queryParam}`, '_blank');
        this.$router.push({ path: `/result?${queryParam}` });
      }

      this.$gtm.trackEvent({
        event: 'search',
        type: 'advance search',
        search_keyword: queryParam,
        search_query: `/result?${queryParam}`,
      });
    },
    onSearch(value, loading) {
      this.search = value;
      const aggregations = this.$store.state.aggregations;
      const searcher = new FuzzySearch(aggregations, ['key'], {
        caseSensitive: false,
      });
      loading(true);
      const debouncedFunc = this.debounce(() => {
        if (this.search != '') {
          const filterList = searcher.search(value);
          this.optionList = filterList.map(law => law.key);
        } else {
          this.optionList = [];
        }
        loading(false);
      }, 450);
      debouncedFunc();
    },
    debounce(func, wait) {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          // clearTimeout(timeout);
          timeout = null;
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
  },
};
</script>
