<template>
  <div v-if="isShowModal">
    <div v-if="isShowModal" class="opacity-50 fixed inset-0 z-50 bg-black" @click="onToggle"></div>
    <div
      v-if="isShowModal"
      class="
        fixed
        top-[50%]
        md:top-[40%]
        left-[50%]
        z-50
        h-[200px]
        transform
        -translate-x-1/2 -translate-y-full
        w-auto
      "
      :class="width"
    >
      <div class="relative w-auto my-6 mx-auto max-w-6x">
        <!--content-->
        <div
          class="
            border-0
            rounded-xl
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
            outline-none
            focus:outline-none
          "
        >
          <!--header-->
          <div
            v-if="title.length > 0"
            class="
              flex
              items-start
              justify-between
              p-6
              border-b border-solid border-blueGray-200
              rounded-t
            "
          >
            <h3 class="text-xl font-semibold">{{ title }}</h3>
            <div class="cursor-pointer" @click="onToggle()">
              <font-awesome-icon class="text-gray-500" :icon="['fas', 'times']" />
            </div>
          </div>
          <!--body-->
          <div class="relative p-6 py-2 flex-auto overflow-y-auto" :class="height">
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              <slot></slot>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    setModalShow: {
      type: Function,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {};
  },
  computed: {
    width() {
      switch (this.size) {
        case 'md':
          return 'md:w-[500px]';
        case 'lg':
          return 'md:min-w-[720px]';
        default:
          return 'md:w-[720px]';
      }
    },
    height() {
      switch (this.size) {
        case 'xl':
          return 'md:max-h-auto';
        default:
          return 'max-h-auto';
      }
    },
  },
  unmounted() {
    document.documentElement.classList.remove('stop-scrolling');
  },
  watch: {
    // whenever question changes, this function will run
    isShowModal: function (newVal) {
      if (newVal == false) {
        document.documentElement.classList.remove('stop-scrolling');
      } else {
        document.documentElement.classList.add('stop-scrolling');
      }
    },
  },
  methods: {
    onToggle() {
      this.setModalShow(!this.isShowModal);
    },
  },
};
</script>
<style>
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
</style>
