<template>
  <div
    class="flex border-2 w-full p-4 overflow-hidden"
    label="code"
    :decision-position="decisionPosition"
  >
    <div class="flex space-x-4">
      <div class="flex flex-col justify-center items-center">
        <button
          :decision-id="getDecisionID(data['_source'])"
          class="
            w-[30px]
            h-[30px]
            hover:ring-2
            focus:ring-purple-600 focus:text-purple-600 focus:ring-opacity-50
          "
          :class="{ 'text-purple-600': stateVote == 'up' }"
          @click="vote('up')"
        >
          <i class="fas fa-arrow-up"></i>
        </button>
        <div class="py-4"></div>
        <button
          :decision-id="getDecisionID(data['_source'])"
          class="
            w-[30px]
            h-[30px]
            hover:ring-2
            focus:ring-purple-600 focus:text-purple-600 focus:ring-opacity-50
          "
          :class="{ 'text-purple-600': stateVote == 'down' }"
          @click="vote('down')"
        >
          <i class="fas fa-arrow-down"></i>
        </button>
      </div>
      <div class="flex flex-col px-2">
        <div class="font-bold">
          {{ data['_source']['law_item'][0]['articles'][0] }}
          {{ data['_source']['law_item'][0].law }}
        </div>
        <p class="mt-2" v-html="summary"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    decisionPosition: {
      type: Number,
    },
    data: {
      type: Object,
    },
    query: {
      type: Object,
    },
    token: {
      type: Array,
    },
  },
  computed: {
    redirectUrl() {
      let queryParam = '';
      queryParam = this.query.doc_type
        ? `${queryParam}&doc_type=${this.query.doc_type}`
        : queryParam;
      queryParam = this.query.keyword ? `${queryParam}&keyword=${this.query.keyword}` : queryParam;
      queryParam = this.query.or_keyword
        ? `${queryParam}&or_keyword=${this.query.or_keyword}`
        : queryParam;
      queryParam = this.query.not_keyword
        ? `${queryParam}&not_keyword=${this.query.not_keyword}`
        : queryParam;
      queryParam = this.query.doc_no ? `${queryParam}&doc_no=${this.query.doc_no}` : queryParam;
      queryParam = this.query.temp_law
        ? `${queryParam}&temp_law=${this.query.temp_law}`
        : queryParam;
      queryParam = this.query.start_year
        ? `${queryParam}&start_year=${this.query.start_year}`
        : queryParam;
      queryParam = this.query.end_year
        ? `${queryParam}&end_year=${this.query.end_year}`
        : queryParam;
      queryParam = this.query.source ? `${queryParam}&source=${this.query.source}` : queryParam;
      queryParam = this.query.page_number
        ? `${queryParam}&page_number=${this.query.page_number}`
        : queryParam;
      queryParam = this.query.judge ? `${queryParam}&judge=${this.query.judge}` : queryParam;
      queryParam = this.query.litigant
        ? `${queryParam}&litigant=${this.query.litigant}`
        : queryParam;
      return `/detail?${queryParam}&id=${this.data['_source']['id']}`;
    },
    summary() {
      let summary = this.data['_source'].long_summary;
      this.token.forEach(item => {
        const regex = new RegExp(item, 'g');
        summary = summary.replace(regex, '<mark>' + item + '</mark>');
      });
      return summary;
    },
  },
  data() {
    return {
      stateVote: '',
    };
  },
  methods: {
    vote(choice) {
      this.stateVote = this.stateVote == choice ? '' : choice;
    },
    navigateDetailPage(data) {
      // Google Tag Manager (event pushing with dataLayer)
      this.$gtm.trackEvent({ ecommerce: null }); // Clear the previous ecommerce object
      this.$gtm.trackEvent({
        ecommerce: {
          detail: {
            products: [
              {
                name: data.title,
                id: this.getDecisionID(data),
              },
            ],
          },
        },
      });
      window.open(`${this.redirectUrl}`, '_blank');
      // this.$router.push(this.redirectUrl);
    },
    getDecisionID(data) {
      const docNo = data.doc_no;
      return docNo;

      // let docType;
      // const docNo = data.id;
      // const law_item = data.law_item[0];
      // const law = law_item.law;
      // const law_code = law_item.articles[0].split(' ')[1];

      // if (law == 'ประมวลกฎหมายอาญา') {
      //   docType = 'LAW_CRIMINAL';
      // } else if (law == 'ประมวลกฎหมายแพ่งและพาณิชย์') {
      //   docType = 'LAW_CIVILnCOM';
      // } else {
      //   docType = 'LAW_OTHER';
      // }

      // let decisionID;
      // if (docType == 'LAW_OTHER') {
      //   decisionID = `${docType}_${docNo}_${law_code}`;
      // } else {
      //   decisionID = `${docType}_${law_code}`;
      // }
      // return decisionID;
    },
  },
};
</script>
<style scoped>
mark {
  background-color: yellow;
  color: black;
}
</style>
