<template>
  <div class="xl:w-[1100px] mx-auto text-left py-6 max-h-screen pt-[80px]">
    <h1 class="lg:fixed text-2xl font-bold pl-4 xl:pl-0">ผลลัพธ์การค้นหา</h1>
    <div class="flex mt-4">
      <div
        class="hidden md:block fixed flex flex-col w-[250px] bg-gray-300 h-screen py-6 px-6 lg:mt-6"
      >
        <div
          class="
            flex flex-row
            w-full
            justify-between
            font-bold
            cursor-pointer
            hover:text-purple-700
          "
          @click="changeDataSource('all')"
        >
          <div>ภาพรวมทั้งหมด</div>
          <div>{{ total }}</div>
        </div>
        <div
          class="flex flex-row justify-between mt-4 cursor-pointer underline hover:text-purple-700"
          @click="changeDataSource('กองผู้ช่วยผู้พิพากษาศาลฎีกา')"
        >
          <div class="max-w-[150px]">ผลลัพธ์การค้นหา</div>
          <div>{{ total }}</div>
        </div>
        <div
          class="flex flex-row justify-between mt-4 cursor-pointer underline hover:text-purple-700"
          @click="changeDataSource('กฎหมาย-มาตรา')"
        >
          <div class="max-w-[150px]">กฎหมาย-มาตรา</div>
          <div>{{ kridsadikaTotal }}</div>
        </div>
        <div class="mt-4 flex flex-row w-full justify-between font-bold cursor-pointer">
          <div>Reference</div>
        </div>
        <div
          v-for="(item, index) in legalTypes.filter(law => law.label.startsWith('ประมวล'))"
          :key="index"
          class="flex flex-row justify-between mt-4 cursor-pointer underline hover:text-purple-700"
          @click="changeDataSource(item.label)"
        >
          <div class="max-w-[150px]">{{ item.label }}</div>
          <div>{{ mapDocCountLegalTypes(item.label) }}</div>
        </div>
      </div>
      <div class="flex flex-col w-full md:pl-[270px]" v-if="source == 'all'">
        <div class="text-2xl font-bold pl-4 xl:pl-0 xl:mt-4">ภาพรวมทั้งหมด</div>
        <div class="flex space-x-6">
          <div class="flex flex-col mt-4">
            <div>เรียงลำดับโดย</div>
            <select class="border-2 w-[200px] text-md p-[0.35rem]" v-model="filter.relevance">
              <option value="relevance">ความเกี่ยวข้อง</option>
              <option value="year_desc">ปีจากใหม่ไปเก่า</option>
              <option value="year_asc">ปีจากเก่าไปใหม่</option>
            </select>
          </div>
          <div class="flex flex-col mt-4">
            <div>มาตรา</div>
            <v-select class="min-w-[250px]" :options="aggregations" v-model="filter.law" multiple>
              <template v-slot:option="option">
                {{ option.label }} (ทั้งหมด {{ mapDocCount(option.label) }})
              </template></v-select
            >
          </div>
        </div>
        <div class="flex flex-col xl:flex-row mt-4 pl-4 xl:pl-0">
          <div class="text-xl">ผลลัพธ์การค้นหา</div>
          <div class="px-6 hidden xl:block">|</div>
          <a
            class="cursor-pointer text-xl underline text-purple-600 hover:text-purple-700"
            @click="changeDataSource('กองผู้ช่วยผู้พิพากษาศาลฎีกา')"
            >แสดงทั้งหมด {{ total }}</a
          >
        </div>
        <div v-if="!state.loading">
          <div v-for="(item, index) in results.slice(0, 5)" :key="index" class="mt-4">
            <ResultBox
              label="code"
              :decisionPosition="index + 1"
              :data="item"
              :query="$route.query"
              :token="token"
              :showModal="handleResultClick"
            />
          </div>
        </div>
        <!-- <hr /> -->
        <div class="flex flex-col xl:flex-row mt-10 pl-4 xl:pl-0">
          <div class="text-xl">กฏหมาย-มาตรา</div>
          <div class="px-6 hidden xl:block">|</div>
          <a
            class="cursor-pointer underline text-purple-600 hover:text-purple-700 text-xl"
            @click="changeDataSource('กฎหมาย-มาตรา')"
            >แสดงทั้งหมด {{ kridsadikaTotal }}</a
          >
        </div>
        <div v-if="!state.loading">
          <div v-for="(item, index) in kridsadika.results.slice(0, 5)" :key="index" class="mt-4">
            <LawResultBox
              label="code"
              :decisionPosition="index + 1"
              :data="item"
              :query="$route.query"
              :token="token"
            />
          </div>
        </div>
        <div class="flex justify-center pt-8 h-[300px]" v-if="state.loading"><PulseLoader /></div>
      </div>
      <div
        class="flex flex-col w-full md:pl-[270px] pb-8"
        v-if="source == 'กองผู้ช่วยผู้พิพากษาศาลฎีกา'"
      >
        <div
          class="text-xl text-custom-purple py-2 pl-4 xl:pl-0 xl:hidden"
          @click="changeDataSource('all')"
        >
          ย้อนกลับ
        </div>
        <div class="text-xl xl:text-2xl font-bold pl-4 xl:pl-0">
          <b>กองผู้ช่วยผู้พิพากษาศาลฎีกา</b> ({{ total }})
        </div>

        <div v-if="!state.loading">
          <div class="flex space-x-6">
            <div class="flex flex-col mt-4">
              <div>เรียงลำดับโดย</div>
              <select class="border-2 w-[200px] text-md p-[0.35rem]" v-model="filter.relevance">
                <option value="relevance">ความเกี่ยวข้อง</option>
                <option value="year_desc">ปีจากใหม่ไปเก่า</option>
                <option value="year_asc">ปีจากเก่าไปใหม่</option>
              </select>
            </div>
            <div class="flex flex-col mt-4">
              <div>มาตรา</div>
              <v-select class="min-w-[250px]" :options="aggregations" v-model="filter.law" multiple>
                <template v-slot:option="option">
                  {{ option.label }} (ทั้งหมด {{ mapDocCount(option.label) }})
                </template></v-select
              >
            </div>
          </div>
          <div v-for="(item, index) in results.slice(0, 10)" :key="index" class="mt-4">
            <ResultBox
              label="code"
              :decisionPosition="index + 1"
              :data="item"
              :query="$route.query"
              :token="token"
              :showModal="handleResultClick"
            />
          </div>
          <div class="flex justify-center mt-8">
            <Pagination :total="total" />
          </div>
        </div>
        <div class="flex justify-center pt-8 h-[300px]" v-if="state.loading"><PulseLoader /></div>
      </div>
      <div class="flex flex-col w-full md:pl-[270px] pb-8" v-if="source == 'กฎหมาย-มาตรา'">
        <div
          class="text-xl text-custom-purple py-2 pl-4 xl:pl-0 xl:hidden"
          @click="changeDataSource('all')"
        >
          ย้อนกลับ
        </div>
        <div class="text-xl xl:text-2xl font-bold pl-4 xl:pl-0">
          <b class="text-bold">กฎหมาย-มาตรา</b> ({{ kridsadikaTotal }})
        </div>

        <div v-if="!state.loading">
          <div class="flex space-x-6">
            <div class="flex flex-col mt-4">
              <div>ประเภทกฏหมาย-มาตรา</div>
              <v-select class="min-w-[300px]" :options="legalTypes" v-model="filter.type" multiple>
                <template v-slot:option="option">
                  {{ option.label }}
                </template></v-select
              >
            </div>
          </div>
          <div v-for="(item, index) in kridsadika.results.slice(0, 10)" :key="index" class="mt-4">
            <LawResultBox
              label="code"
              :decisionPosition="index + 1"
              :data="item"
              :query="$route.query"
              :token="token"
            />
          </div>
          <div class="flex justify-center mt-8">
            <Pagination :total="kridsadikaTotal" />
          </div>
        </div>
        <div class="flex justify-center pt-8 h-[300px]" v-if="state.loading"><PulseLoader /></div>
      </div>

      <div
        class="flex flex-col w-full md:pl-[270px] pb-8"
        v-if="
          source != 'all' && source != 'กฎหมาย-มาตรา' && source != 'กองผู้ช่วยผู้พิพากษาศาลฎีกา'
        "
      >
        <div
          class="text-xl text-custom-purple py-2 pl-4 xl:pl-0 xl:hidden"
          @click="changeDataSource('all')"
        >
          ย้อนกลับ
        </div>
        <div class="text-xl xl:text-2xl font-bold pl-4 xl:pl-0">
          <b class="text-bold">{{ source }}</b> ({{ mapDocCountLegalTypes(source) }})
        </div>

        <div v-if="!state.loading">
          <div v-for="(item, index) in kridsadika.results.slice(0, 10)" :key="index" class="mt-4">
            <LawResultBox
              label="code"
              :decisionPosition="index + 1"
              :data="item"
              :query="$route.query"
              :token="token"
            />
          </div>
          <div class="flex justify-center mt-8">
            <Pagination :total="kridsadikaTotal" />
          </div>
        </div>
        <div class="flex justify-center pt-8 h-[300px]" v-if="state.loading"><PulseLoader /></div>
      </div>
    </div>

    <Modal :isShowModal="modal.isShow" :setModalShow="showModal">
      <div v-if="!modal.loading">
        <div v-if="modal.article">
          <div class="text-xl font-bold">
            {{ modal.article['_source']['law_item'][0]['articles'][0] }}
            {{ modal.article['_source']['law_item'][0]['law'] }}
          </div>
          <p class="mt-4">{{ modal.article['_source']['long_summary'] }}</p>
          <p class="mt-4" v-if="modal.article['_source']['note']">
            {{ modal.article['_source']['note'] }}
          </p>
          <div class="mt-[6rem] text-purple-600 hover:cursor-pointer">
            <a :href="`/result?temp_law=${modal.article['_source']['law_item'][0]['articles'][0]}`"
              >ค้นหาคดีความที่เกี่ยวข้องกับกฎหมาย-มาตรานี้
            </a>
          </div>
        </div>
        <div class="py-6" v-if="!modal.article">
          <div class="text-xl font-bold">ไม่ค้นพบกฎหมาย-มาตราในระบบ</div>
          <div>
            <button
              class="
                w-[30px]
                h-[30px]
                border-black
                hover:ring-2
                focus:ring-purple-600 focus:text-purple-600 focus:ring-opacity-50
              "
              @click="showModal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center h-full" v-else><PulseLoader /></div>
    </Modal>
  </div>
</template>
<script>
import ResultBox from '@/components/ResultBox';
import Pagination from '@/components/Pagination';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import LawResultBox from '@/components/LawResultBox';
import Modal from '@/components/Modal';
import axios from 'axios';

export default {
  components: {
    ResultBox,
    Pagination,
    PulseLoader,
    LawResultBox,
    Modal,
  },
  data() {
    return {
      source: 'all',
      state: {
        loading: false,
      },
      filter: {
        law: '',
        type: '',
        relevance: 'relevance',
      },
      modal: {
        isShow: false,
        lawItem: {},
        article: null,
        loading: false,
      },
    };
  },
  watch: {
    async $route() {
      this.state.loading = true;
      await this.$store.dispatch('fetchResult', this.$route.query);
      this.state.loading = false;
      this.topFunction();
    },
    'filter.law'() {
      if (this.filter.law.length > 0) {
        const query = this.filter.law.reduce(
          (q, law, index) =>
            this.filter.law.length - 1 == index ? q + law.code : q + law.code + ', ',
          ''
        );
        this.$router.push({
          query: Object.assign({}, this.$route.query, { temp_law: query }),
        });
      } else {
        let query = Object.assign({}, this.$route.query);
        delete query.temp_law;
        this.$router.replace({ query });
      }
    },
    'filter.type'() {
      if (this.filter.type.length > 0) {
        const query = this.filter.type.reduce(
          (q, law, index) =>
            this.filter.type.length - 1 == index ? q + law.code : q + law.code + ', ',
          ''
        );
        this.$router.push({
          query: Object.assign({}, this.$route.query, { temp_type: query }),
        });
      } else {
        let query = Object.assign({}, this.$route.query);
        delete query.temp_type;
        this.$router.replace({ query });
      }
    },
    'filter.relevance'() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { sort_type: this.filter.relevance }),
      });

      this.$gtm.trackEvent({
        event: 'sort',
        search_keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        search_query: this.$route.fullPath,
      });
    },
  },
  async mounted() {
    this.state.loading = true;
    await this.$store.dispatch('fetchResult', this.$route.query);
    this.source = this.$route.query.source ? this.$route.query.source : 'all';
    this.filter.law = this.$route.query.temp_law ? this.mapLaw(this.$route.query.temp_law) : [];
    this.filter.type = this.$route.query.temp_type ? this.mapLaw(this.$route.query.temp_type) : [];
    this.filter.relevance = this.$route.query.sort_type ? this.$route.query.sort_type : 'relevance';
    this.state.loading = false;
  },
  computed: {
    results() {
      return this.$store.getters.results;
    },
    total() {
      return this.$store.state.total;
    },
    token() {
      return this.$store.getters.token;
    },
    kridsadika() {
      return this.$store.state.kridsadika;
    },
    kridsadikaTotal() {
      return this.$store.state.kridsadikaTotal;
    },
    aggregations() {
      const aggregations = this.$store.state.aggregations;
      let optionList = aggregations.map(agg => {
        return {
          label: `${agg.key}`,
          code: agg.key,
        };
      });
      return optionList;
    },
    legalTypes() {
      const aggregations = this.$store.state.legalType;
      let optionList = aggregations.map(agg => {
        return {
          label: `${agg.key}`,
          code: agg.key,
        };
      });
      return optionList;
    },
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    changeDataSource(source) {
      this.source = source;
      this.$router.push({ query: Object.assign({}, this.$route.query, { source }) });
      this.removePageQuery();
    },
    removePageQuery() {
      if (this.$route.query.page_number) {
        let query = Object.assign({}, this.$route.query);
        delete query.page_number;
        this.$router.replace({ query });
      }
    },
    mapLaw(law) {
      const laws = law.split(', ');
      return laws.map(law => {
        return {
          label: `${law}`,
          code: law,
        };
      });
    },
    mapDocCount(label) {
      const law = this.$store.state.aggregations.find(law => law.key == label);
      return law.doc_count;
    },
    mapDocCountLegalTypes(label) {
      const law = this.$store.state.legalType.find(law => law.key == label);
      return law.doc_count;
    },
    setModalLawItem(lawItem) {
      this.modal.lawItem = lawItem;
    },
    showModal() {
      this.modal.isShow = !this.modal.isShow;
    },
    async fetchLawItem() {
      this.modal.loading = true;
      const response = await axios.post(
        'https://api.legal-search.airesearch.in.th/search-advance-krisdika',
        {
          temp_law: this.modal.lawItem.temp_law,
          page_number: 1,
          num_show_item: 100,
        }
      );
      response.data.hits.forEach(
        law =>
          console.log(
            law['_source']['law_item'][0]['law'] +
              ':' +
              law['_source']['law_item'][0]['articles'][0]
          )
        // law['_source']['law_item'][0]['law'] == this.modal.lawItem.law_item &&
        // law['_source']['law_item'][0]['articles'][0] == this.modal.lawItem.temp_law
      );
      this.modal.article = response.data.hits.find(
        law =>
          law['_source']['law_item'][0]['law'] == this.modal.lawItem.law_item &&
          law['_source']['law_item'][0]['articles'][0] == this.modal.lawItem.temp_law
      );
      this.modal.loading = false;
    },
    handleResultClick(law) {
      console.log(law);
      this.showModal();
      this.setModalLawItem(law);
      this.fetchLawItem();
    },
  },
};
</script>
