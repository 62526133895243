<template>
  <div class="xl:w-[1100px] mx-auto text-left px-4 xl:px-0 py-6 max-h-screen pt-[80px]">
    <router-link
      :to="redirectUrl"
      class="
        color-custom-purple
        cursor-pointer
        no-underline
        hover:underline hover:color-custom-purple
        no-print
      "
    >
      ย้อนกลับ</router-link
    >
    <div v-if="result">
      <div class="text-xl font-bold py-4">{{ result['_source']['title'] }}</div>
      <p v-html="shortSummary"></p>
      <br />
      <p v-html="longSummary"></p>
      <div class="flex mt-8">
        <div
          class="flex-1 flex-row xl:flex-col"
          v-for="(item, index) in result['_source']['law_item']"
          :key="index"
        >
          <div class="font-bold">{{ item.law }}</div>
          <ul class="list-disc pl-4">
            <li v-for="(law, index) in item['articles']" :key="index">{{ law }}</li>
          </ul>
        </div>
      </div>
      <div class="flex mt-8 justify-between">
        <div class="flex-1 flex-col">
          <div class="font-bold">แหล่งที่มา</div>
          <ul class="list-disc pl-4">
            <li>{{ result['_source']['source'] }}</li>
          </ul>
        </div>
        <div class="flex-1 flex-col">
          <div class="font-bold">ชื่อคู่ความ</div>
          <ul class="list-disc pl-4">
            <li v-for="(litigant, index) in result['_source']['litigant']" :key="index">
              {{ litigant }}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex mt-8 justify-between">
        <div class="flex-1 flex-col">
          <div class="font-bold">ชื่อองค์คณะ</div>
          <ul class="list-disc pl-4">
            <li v-for="(judge, index) in result['_source']['judge']" :key="index">{{ judge }}</li>
          </ul>
        </div>
        <div class="flex-1 flex-col">
          <div class="font-bold">ศาลชั้นต้นและศาลอุทธรณ์ที่ตัดสิน</div>
          <ul class="list-disc pl-4">
            <li v-for="(primarycourt, index) in result['_source']['primarycourt']" :key="index">
              {{ primarycourt }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    result() {
      const result = this.$store.getters.resultByID(this.$route.query.id);
      return result;
    },
    token() {
      return this.$store.getters.token;
    },
    redirectUrl() {
      let queryParam = '';
      queryParam = this.$route.query.doc_type
        ? `${queryParam}&doc_type=${this.$route.query.doc_type}`
        : queryParam;
      queryParam = this.$route.query.keyword
        ? `${queryParam}&keyword=${this.$route.query.keyword}`
        : queryParam;
      queryParam = this.$route.query.or_keyword
        ? `${queryParam}&or_keyword=${this.$route.query.or_keyword}`
        : queryParam;
      queryParam = this.$route.query.not_keyword
        ? `${queryParam}&not_keyword=${this.$route.query.not_keyword}`
        : queryParam;
      queryParam = this.$route.query.doc_no
        ? `${queryParam}&doc_no=${this.$route.query.doc_no}`
        : queryParam;
      queryParam = this.$route.query.temp_law
        ? `${queryParam}&temp_law=${this.$route.query.temp_law}`
        : queryParam;
      queryParam = this.$route.query.start_year
        ? `${queryParam}&start_year=${this.$route.query.start_year}`
        : queryParam;
      queryParam = this.$route.query.end_year
        ? `${queryParam}&end_year=${this.$route.query.end_year}`
        : queryParam;
      queryParam = this.$route.query.source
        ? `${queryParam}&source=${this.$route.query.source}`
        : queryParam;
      queryParam = this.$route.query.page_number
        ? `${queryParam}&page_number=${this.$route.query.page_number}`
        : queryParam;
      queryParam = this.$route.judge ? `${queryParam}&judge=${this.$route.judge}` : queryParam;
      queryParam = this.$route.litigant
        ? `${queryParam}&litigant=${this.$route.litigant}`
        : queryParam;
      queryParam = this.$route.sort_type
        ? `${queryParam}&sort_type=${this.$route.sort_type}`
        : queryParam;
      return `/result?${queryParam}`;
    },
    longSummary() {
      let summary = this.result['_source'].long_summary;
      if (this.token.length > 0) {
        this.token.forEach(item => {
          const regex = new RegExp(item, 'g');
          summary = summary.replace(regex, '<mark>' + item + '</mark>');
        });
        const matchAll = this.token.reduce(
          (previousValue, currentValue) => previousValue + `<mark>${currentValue}</mark>`,
          ''
        );
        const regex = new RegExp(matchAll, 'g');
        summary = summary.replace(
          regex,
          '<span style="background-color: #ff7f7f !important;">' +
            this.$route.query.keyword +
            '</span>'
        );
      }

      return summary;
    },
    shortSummary() {
      let summary = this.result['_source'].short_summary;
      if (this.token.length > 0) {
        this.token.forEach(item => {
          const regex = new RegExp(item, 'g');
          summary = summary.replace(regex, '<mark>' + item + '</mark>');
        });
        const matchAll = this.token.reduce(
          (previousValue, currentValue) => previousValue + `<mark>${currentValue}</mark>`,
          ''
        );
        const regex = new RegExp(matchAll, 'g');
        summary = summary.replace(
          regex,
          '<span style="background-color: #ff7f7f !important;">' +
            this.$route.query.keyword +
            '</span>'
        );
      }
      return summary;
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchResult', this.$route.query);
  },
};
</script>
<style scoped>
/* @page {
  size: A4;
  margin: 0;
} */

@media print {
  .no-print {
    display: none;
  }

  /* html,
  body {
    width: 210mm;
    height: 297mm;
  } */
}
</style>
