<template>
  <div>
    <div>
      <nav
        class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
        aria-label="Pagination"
      >
        <a
          class="
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-l-md
            border border-gray-300
            bg-white
            text-sm
            font-medium
            text-gray-500
            hover:bg-gray-50
          "
          @click="changePreviousPage()"
        >
          <span class="sr-only">Previous</span>
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
        <a
          aria-current="page"
          class="
            bg-white
            border-gray-300
            text-gray-500
            hover:bg-gray-50
            hidden
            md:inline-flex
            relative
            items-center
            px-4
            py-2
            border
            text-sm
            font-medium
          "
          :class="{
            'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center':
              item == currentPage,
          }"
          v-for="item in firstSet"
          :key="item"
          @click="changePage(item)"
        >
          {{ item }}
        </a>
        <span
          class="
            relative
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            bg-white
            text-sm
            font-medium
            text-gray-700
          "
          v-if="totalPage > 5"
        >
          ...
        </span>
        <div v-if="totalPage > 5">
          <a
            aria-current="page"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              hidden
              md:inline-flex
              relative
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
            :class="{
              'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center':
                item == currentPage,
            }"
            v-for="item in secondSet"
            :key="item"
            @click="changePage(item)"
          >
            {{ item }}
          </a>
        </div>

        <a
          class="
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-r-md
            border border-gray-300
            bg-white
            text-sm
            font-medium
            text-gray-500
            hover:bg-gray-50
          "
          @click="changeNextPage()"
        >
          <span class="sr-only">Next</span>
          <!-- Heroicon name: solid/chevron-right -->
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
      firstSet: [],
      secondSet: [],
      totalPage: 0,
    };
  },
  mounted() {
    this.totalPage = this.calculateTotalPage();
    this.calculatePage();
    console.log('sss');
    this.currentPage = this.$route.query.page_number ? parseInt(this.$route.query.page_number) : 1;
  },
  watch: {
    $route() {
      if (this.$route.query.page_number) {
        this.currentPage = parseInt(this.$route.query.page_number);
        this.calculatePage();
      }
    },
    total() {
      this.totalPage = this.calculateTotalPage();
      this.calculatePage();
    },
  },
  methods: {
    calculateTotalPage() {
      const totalPage = Math.ceil(this.total / this.perPage);
      return totalPage;
    },
    calculatePage() {
      if (this.totalPage > 5) {
        if (this.currentPage < this.totalPage - 4) {
          this.firstSet = [this.currentPage, this.currentPage + 1, this.currentPage + 2];
          this.secondSet = [this.totalPage - 2, this.totalPage - 1, this.totalPage];
        } else {
          this.firstSet = [this.totalPage - 5, this.totalPage - 4, this.totalPage - 3];
          this.secondSet = [this.totalPage - 2, this.totalPage - 1, this.totalPage];
        }
      } else {
        this.firstSet = [];
        let newSet = [];
        for (var i = 1; i <= this.totalPage; i++) {
          newSet.push(i);
        }
        this.firstSet = newSet;
      }
    },
    changePreviousPage() {
      this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : this.currentPage;
      this.$router.push({
        path: 'result',
        query: { ...this.$route.query, page_number: this.currentPage },
      });
    },
    changeNextPage() {
      this.currentPage =
        this.currentPage + 1 <= this.totalPage ? this.currentPage + 1 : this.currentPage;
      this.$router.push({
        path: 'result',
        query: { ...this.$route.query, page_number: this.currentPage },
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.$router.push({ path: 'result', query: { ...this.$route.query, page_number: page } });
    },
  },
};
</script>
