<template>
  <div
    class="flex border-2 w-full p-4 overflow-hidden hover:cursor-pointer"
    v-on:click="navigateDetailPage(data['_source'])"
    label="code"
    :decision-id="getDecisionID(data['_source'])"
    :decision-position="decisionPosition"
    :decision-title="data['_source'].title"
  >
    <div class="flex space-x-4">
      <div class="flex flex-col justify-center items-center">
        <button
          :decision-id="getDecisionID(data['_source'])"
          class="
            w-[30px]
            h-[30px]
            hover:ring-2
            focus:ring-purple-600 focus:text-purple-600 focus:ring-opacity-50
          "
          :class="{ 'text-purple-600': stateVote == 'up' }"
          @click="vote('up')"
          @click.stop="() => {}"
        >
          <i class="fas fa-arrow-up"></i>
        </button>
        <div class="py-4"></div>
        <button
          :decision-id="getDecisionID(data['_source'])"
          class="
            w-[30px]
            h-[30px]
            hover:ring-2
            focus:ring-purple-600 focus:text-purple-600 focus:ring-opacity-50
          "
          :class="{ 'text-purple-600': stateVote == 'down' }"
          @click="vote('down')"
          @click.stop="() => {}"
        >
          <i class="fas fa-arrow-down"></i>
        </button>
      </div>
      <div class="flex flex-col px-2">
        <div class="font-bold">{{ data['_source'].title }}</div>
        <p class="mt-2 overflow-ellipsis overflow-hidden" v-html="summary"></p>
        <div class="flex flex-wrap mt-2">
          <div
            class="flex-[50%] flex-col"
            v-for="(item, index) in data['_source']['law_item']"
            :key="item + index"
          >
            <div class="font-bold">{{ item.law }}</div>
            <ul
              class="list-disc pl-4"
              v-if="item['articles'].length > 0 && item['articles'][0] != ''"
            >
              <li
                v-for="(article, index) in item['articles']"
                :key="article + index"
                class="hover:text-purple-600 hover:cursor-pointer"
                @click.stop="showModal({ law_item: item.law, temp_law: article })"
              >
                {{ article }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    decisionPosition: {
      type: Number,
    },
    data: {
      type: Object,
    },
    query: {
      type: Object,
    },
    token: {
      type: Array,
    },
    showModal: {
      type: Function,
    },
  },
  computed: {
    redirectUrl() {
      let queryParam = '';
      queryParam = this.query.doc_type
        ? `${queryParam}&doc_type=${this.query.doc_type}`
        : queryParam;
      queryParam = this.query.keyword ? `${queryParam}&keyword=${this.query.keyword}` : queryParam;
      queryParam = this.query.or_keyword
        ? `${queryParam}&or_keyword=${this.query.or_keyword}`
        : queryParam;
      queryParam = this.query.not_keyword
        ? `${queryParam}&not_keyword=${this.query.not_keyword}`
        : queryParam;
      queryParam = this.query.doc_no ? `${queryParam}&doc_no=${this.query.doc_no}` : queryParam;
      queryParam = this.query.temp_law
        ? `${queryParam}&temp_law=${this.query.temp_law}`
        : queryParam;
      queryParam = this.query.start_year
        ? `${queryParam}&start_year=${this.query.start_year}`
        : queryParam;
      queryParam = this.query.end_year
        ? `${queryParam}&end_year=${this.query.end_year}`
        : queryParam;
      queryParam = this.query.source ? `${queryParam}&source=${this.query.source}` : queryParam;
      queryParam = this.query.page_number
        ? `${queryParam}&page_number=${this.query.page_number}`
        : queryParam;
      queryParam = this.query.judge ? `${queryParam}&judge=${this.query.judge}` : queryParam;
      queryParam = this.query.litigant
        ? `${queryParam}&litigant=${this.query.litigant}`
        : queryParam;
      queryParam = this.query.sort_type
        ? `${queryParam}&sort_type=${this.query.sort_type}`
        : queryParam;
      return `/detail?${queryParam}&id=${this.data['_source']['id']}`;
    },
    summary() {
      let summary = '';
      if ('highlight' in this.data) {
        summary = this.data['highlight']['short_summary']
          ? this.data['highlight']['short_summary'].reduce((old, current) => old + current)
          : '';
        summary = this.data['highlight']['long_summary']
          ? this.data['highlight']['long_summary'].reduce((old, current) => old + current)
          : summary;
      } else {
        summary = this.data['_source']['long_summary']
          ? this.data['_source']['long_summary']
          : this.data['_source']['short_summary'];
      }
      if (this.token.length > 0) {
        this.token.forEach(item => {
          const regex = new RegExp(item, 'g');
          summary = summary.replace(regex, '<mark>' + item + '</mark>');
        });
        const matchAll = this.token.reduce(
          (previousValue, currentValue) => previousValue + `<mark>${currentValue}</mark>`,
          ''
        );
        const regex = new RegExp(matchAll, 'g');
        summary = summary.replace(
          regex,
          '<span style="background-color: #ff7f7f !important;">' + this.query.keyword + '</span>'
        );
      }

      return summary;
    },
  },
  data() {
    return {
      stateVote: '',
    };
  },
  methods: {
    vote(choice) {
      this.stateVote = this.stateVote == choice ? '' : choice;
    },
    navigateDetailPage(data) {
      // Google Tag Manager (event pushing with dataLayer)
      this.$gtm.trackEvent({ ecommerce: null }); // Clear the previous ecommerce object
      this.$gtm.trackEvent({
        event: 'decisionClick',
        ecommerce: {
          click: {
            actionField: { list: this.$route.fullPath },
            products: [
              {
                name: data.title,
                id: this.getDecisionID(data),
                position: this.decisionPosition,
              },
            ],
          },
        },
      });
      window.open(`${this.redirectUrl}`, '_blank');
      // this.$router.push(this.redirectUrl);
    },
    getDecisionID(data) {
      let docType = 'OTHER';
      if (data.doc_type == 'คำพิพากษาศาลฎีกา') {
        docType = 'DEKA';
      }
      if (data.doc_type == 'คำวินิจฉัย') {
        docType = 'DECISION';
      }
      // Replace "/" in doc number with "_"
      const docNo = data.doc_no.replace('/', '_');
      const decisionID = `${docType}${docNo}`;
      return decisionID;
    },
  },
};
</script>
<style scoped>
mark {
  background-color: yellow;
  color: black;
}
#ext {
  background-color: red !important;
  color: black;
}
p {
  display: -webkit-box;
  max-width: auto;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
